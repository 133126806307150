import { render, staticRenderFns } from "./Approve.vue?vue&type=template&id=de3dd08a&scoped=true&"
import script from "./Approve.vue?vue&type=script&lang=js&"
export * from "./Approve.vue?vue&type=script&lang=js&"
import style0 from "./Approve.vue?vue&type=style&index=0&id=de3dd08a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3dd08a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VApp,VAvatar,VBtn,VChip,VCol,VContainer,VFlex,VIcon,VImg,VLayout,VRow,VSnackbar,VTooltip})
